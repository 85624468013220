import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { SearchBox, ISearchBoxStyles, getTheme } from "office-ui-fabric-react";

import { useDelay } from "../../hooks/useDelay";
import { IReload } from "../../../../config/redux/reports/reportsAction.types";
import {
  filter,
} from "../../../../config/redux/filters/filterActions";
import { togglefiltartion } from "../../../../config/redux/filters/filterActions";
  
const theme = getTheme();

const searchBoxS: ISearchBoxStyles = {
  root: {
    backgroundColor: theme.palette.white,
    opacity: 0.8,
    width: "320px",
  },

  field: {
    selectors: {
      "::placeholder": {
        color: theme.palette.themeDarker,
      },
    },
  },
};

interface ISearchBoxProps {
  reload: (data: IReload) => any;
}

const SearchB: React.FC<ISearchBoxProps> = ({ reload }) => {
  const dispatch = useDispatch();
  
  return (
    <form>
      <SearchBox
        styles={searchBoxS}
        placeholder="Szukaj sprawy"
        name="searchPhrase"
        onChange={(_, newValue) => 
          {
            dispatch(togglefiltartion({ filter: true }));
            dispatch(filter({ searchPhrase: newValue }));
          }}
        onSearch={() => {}}
        type="text"
      />
    </form>
  );
};

export default SearchB;
