import React from "react"
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";

import { toggleExcelPanel } from "../../../config/redux/excel/excelAction";
import ExcelForm from "./ExcelForm";

import {panelS} from "../../common/styles/panelStyles"

const ExcelPanel: React.SFC = () => {
  const dispatch = useDispatch();  
    const { reportPanelOpen } = useSelector(
      (state: RootStateOrAny) => state.excel
    );

    const dissmisPanel = () => {
      dispatch(toggleExcelPanel({open: false}));
    };

    return (
        <Panel
          headerText="Pobierz raport"
          isOpen={reportPanelOpen}
          closeButtonAriaLabel="Close"
          type={1}
          onDismiss={dissmisPanel}
          isLightDismiss={false}
          isHiddenOnDismiss={false}
          styles={panelS}
        >
        <ExcelForm />
    </Panel>
        );
}

export default ExcelPanel;