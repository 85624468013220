import React from "react";
import ReactDOM from "react-dom";
import { App } from "./App";
import { FluentCustomizations } from "@uifabric/fluent-theme";
import {
  Customizer,
  mergeStyles,
  getTheme,
} from "office-ui-fabric-react";
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
import authentication from 'react-azure-b2c';
import {instance} from "./config"

const theme = getTheme();

// global styles
mergeStyles({
  selectors: {
    ":global(body), :global(html), :global(#root)": {
      margin: 0,
      padding: 0,
      fontFamily: "Segoe UI, sans-serif",
    },
    ":global(*) :global(*::after) :global(*::before)": {
      boxSizing: "border-box",
    },
    ":global(body)": {
      color: theme.palette.neutralDark,
    },
  },
});

authentication.initialize({
    instance: 'https://k365ppw.onmicrosoft.com',
    tenant: 'k365ppw.onmicrosoft.com',
    signInPolicy: 'B2C_1_Kancelaria365',
    clientId: '232e011d-6f7c-4b9a-86e2-d8c08bed8a1e',
    cacheLocation: 'sessionStorage',
    scopes: ['https://k365ppw.onmicrosoft.com/232e011d-6f7c-4b9a-86e2-d8c08bed8a1e/read'],
    //redirectUri: 'http://localhost:3000', 
    redirectUri: 'https://k365.kancelaria-ppw.pl',

    postLogoutRedirectUri: 'https://login.microsoftonline.com/common/oauth2/logout',

});

sessionStorage.removeItem('role');
sessionStorage.removeItem('isActivated');
sessionStorage.removeItem('hasAllRequiredData');

initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/');
authentication.run(() => {
ReactDOM.render(
  <Customizer {...FluentCustomizations}>
    <App  />
  </Customizer>,
  document.getElementById("root")
);
});
