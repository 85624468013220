import React from "react";

import { Formik, Form } from "formik";

import NewFormField from "../../common/layout/form/NewFormField";
import FormDropDown from "../../common/layout/form/FormDropDown";
import NewFormBtns from "../../common/layout/form/NewFormBtns";
import Btn from "../../common/layout/form/Btn";

import ActivityIndicator from "../../common/layout/ActivityIndicator";

import { useReportForm } from "../hooks/useReportForm";
import { Stack, Pivot, PivotItem, } from "office-ui-fabric-react";
import { validationSchemaReports } from "../actions/validationSchemas";
import MessageContainer from "./MessageContainer";

import { IButtonProps } from '@fluentui/react/lib/Button';
import { useBoolean } from '@fluentui/react-hooks';

import { Icon, IPivotItemProps } from '@fluentui/react';
export interface ReportFormProps {
    users: any[];
    userinfo: any;
}

function _customRenderer(
    link?: IPivotItemProps,
    defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
): JSX.Element | null {
    if (!link || !defaultRenderer) {
        return null;
    }

    return (
        <span style={{ flex: '0 0 100%' }}>
            {defaultRenderer({ ...link, itemIcon: undefined })}
            <Icon iconName={link.itemIcon} style={{ color: 'red', marginLeft: '3px' }} />
        </span>
    );
}

const ReportShow: React.FC<ReportFormProps> = ({ users, userinfo }) => {
    const {
        handleSubmit,
        initValues,
        cancelForm,
        selectedReports,
        loading,
        isRecordBold
    } = useReportForm(users, userinfo);
    //const options: Array<any> = getUsers();
    const halfWidthStyle = {
        width: "50%",
        paddingRight: "50%",
        margin: "0px",
        paddingBottom: 20
    }

    const bottomPadding = {
        paddingBottom: 20
    }
    const [selectedKey, setSelectedKey] = React.useState(0);

    const MovetoCurrentPivot = (item: any) => {
        setSelectedKey(item.props.itemKey);
    }

    return (
        <Formik
            initialValues={initValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchemaReports}
            validateOnChange={false}
        //validateOnBlur={false}
        >
            {({ handleSubmit, errors }) => (
                <Form translate="yes" onSubmit={handleSubmit}>
                    <div>
                        <div style={{ zIndex: 1 }}>
                            <Pivot onLinkClick={MovetoCurrentPivot} aria-label="Basic Pivot Example" selectedKey={String(selectedKey)} style={{ zIndex: 1 }}>
                                <PivotItem headerText="Informacje podstawowe" onRenderItemLink={_customRenderer} itemKey="0">
                                    <div style={halfWidthStyle}>
                                        <div style={{ paddingTop: '20px' }}>
                                            <NewFormField name="leadingPersonPhoneNumber" label="Osoba prowadząca" disabled={true} bold={isRecordBold("leadingpersonobjectid")}/>
                                        </div>
                                        <NewFormField name="proceedingType" label="Rodzaj postępowania" disabled={true} bold={isRecordBold("proceedingType")} />
                                        <NewFormField name="bankStatus" label="Status SGB jako strony" disabled={true} bold={isRecordBold("bankStatus")} />
                                    </div>
                                    <div>
                                        <NewFormField name="opposingParty" label="Oznaczenie/nazwa strony przeciwnej/innych uczestników postępowania" disabled={true} bold={isRecordBold("opposingParty")} />
                                        <NewFormField name="court" label="Sąd (nazwa i miejscowość)" disabled={true} bold={isRecordBold("court")} />
                                    </div>
                                    <div style={halfWidthStyle}>
                                        <NewFormField name="caseFileSignature" label="Sygnatura akt sprawy" disabled={true} bold={isRecordBold("caseFileSignature")} />
                                        <NewFormField name="sueDate" label="Data pozwu/pisma wszczynającego postępowanie" type="date" disabled={true} bold={isRecordBold("sueDate")} />
                                    </div>
                                </PivotItem>
                                <PivotItem headerText="Przedmiot sprawy" itemKey="1" >
                                    <div style={bottomPadding} />
                                    <NewFormField name="subjectMatter" label="Przedmiot sprawy" disabled={true} required={false} bold={isRecordBold("subjectMatter")} />
                                    <NewFormField name="caseCourse" label="Przebieg sprawy" multiline rows={5} disabled={true} bold={isRecordBold("caseCourse")} />
                                    <div style={halfWidthStyle}>
                                        <NewFormField name="hasFinished" label="Sprawa zakończona?" disabled={true} bold={isRecordBold("hasFinished")} />
                                    </div>
                                    <NewFormField name="riskAssesment" label="Ocena sprawy i ryzyka" type="textarea" disabled={true} bold={isRecordBold("riskAssesment")} />
                                    <div style={halfWidthStyle}>
                                        <NewFormField name="subjectMattersValue" label="Wartość przedmiotu sporu - w PLN" type="number" disabled={true} bold={isRecordBold("subjectMattersValue")} />
                                        <NewFormField name="conductRisk" label="Czy sprawa powiązana z Conduct Risk?" disabled={true} bold={isRecordBold("conductRisk")} />
                                    </div>
                                </PivotItem>
                                <PivotItem headerText="Koszty i opłaty" itemKey="2">
                                    <div style={bottomPadding} />
                                    <div style={halfWidthStyle}>
                                        <NewFormField name="legalFees" label="Opłaty sądowe - w PLN" type="number" disabled={true} bold={isRecordBold("legalFees")} />
                                        <NewFormField name="representationCost" label="Koszty zastępstwa procesowego" type="number" disabled={true} bold={isRecordBold("representationCost")} />
                                        <NewFormField name="otherCosts" label="Inne koszty - w PLN" type="number" disabled={true} bold={isRecordBold("otherCosts")} />
                                        <NewFormField name="claimValue" label="Wartość roszczenia" type="number" disabled={true} bold={isRecordBold("claimValue")} />
                                        <NewFormField name="losingProbability" label="Prawdopodobieństwo przegrania sprawy przez Bank" disabled={true} bold={isRecordBold("losingProbability")} />
                                        <NewFormField name="endsThisYear" label="Czy sprawa zakończy się w bieżącym roku kalendarzowym?" disabled={true} bold={isRecordBold("endsThisYear")} />
                                    </div>
                                </PivotItem>
                            </Pivot>
                        </div>
                        <Btn
                            cancelForm={cancelForm}
                            resetBtnText={"Zamknij"}
                            positionAbsolute={true}
                        />
                        {loading ? <ActivityIndicator /> : <MessageContainer />}
                    </div>
                </Form>
            )}
        </Formik>

    );
};
export default ReportShow;