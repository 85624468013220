import { IColumn } from "@fluentui/react";
import {FiltersActions} from "./filteractions.enum"
import { ISearch, IFilter, ISort } from './filterActions.types';

export const filter = (data: ISearch) => {
  return {
    type: FiltersActions.SET_SEARCH_PHRASE,
    payload: data
  }
}

export const sort = (data: ISort) => {
  return {
    type: FiltersActions.SET_SORTING,
    payload: data
  }
}

export const togglefiltartion = (data: IFilter) => {
  return {
    type: FiltersActions.TOGGLE_FILTRATION,
    payload: data
  }
}

export const clearFilters = () => {
  return {
    type: FiltersActions.ClEAR
  }
}