import {
  MessageBar,
  MessageBarType,
  IMessageBarStyles,
} from "office-ui-fabric-react";
import React, { useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { clearAlerts, clearListMessages } from "../../../config/redux/alerts/alertsActions";
import { useEffectAfterMount } from "../hooks/useEffectAfterMount";

const messageBarS: IMessageBarStyles = {
  root: {
    // width: "95%",
    marginRight: "auto",
    // marginTop: 10,
    marginLeft: "auto",
  },
};

export interface ListSuccesMessageProps {}

const ListSuccesMessage: React.FC<ListSuccesMessageProps> = () => {
  const dispatch = useDispatch();
  const { listMessage, listErr } = useSelector((state: RootStateOrAny) => state.alerts);

  useEffectAfterMount(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (listMessage) {
      timer = setTimeout(() => {
        dispatch(clearListMessages());
      }, 3000);
    }

    return () => {
      if (listMessage) {
        dispatch(clearListMessages());
      }

      clearTimeout(timer);
    };
  }, [listMessage]);

  useEffectAfterMount(() => {
    let timer: ReturnType<typeof setTimeout>;

    if (listErr) {
      timer = setTimeout(() => {
        dispatch(clearListMessages());
      }, 3000);
    }

    return () => {
      if (listErr) {
        dispatch(clearListMessages());
      }

      clearTimeout(timer);
    };
  }, [listErr]);

  return (
    <>
      {listMessage && (
        <MessageBar
          messageBarType={MessageBarType.success}
          styles={messageBarS}
        >
          {listMessage}
        </MessageBar>
      )}
      {listErr && (
        <MessageBar
          messageBarType={MessageBarType.error}
          styles={messageBarS}
        >
          {listErr}
        </MessageBar>
      )}
    </>
  );
};

export default ListSuccesMessage;
