import * as React from "react";

import { Formik, Form } from "formik";

import NewFormField from "../../common/layout/form/NewFormField";
import FormDropDown from "../../common/layout/form/FormDropDown";
import NewFormBtns from "../../common/layout/form/NewFormBtns";
import ActivityIndicator from "../../common/layout/ActivityIndicator";
import PanelHeader from "../../common/layout/PanelHeader";

import { useExcelForm } from "../hooks/useExcelForm";

import { Stack, Pivot, PivotItem } from "office-ui-fabric-react";
import { validationSchemaReports } from "../actions/validationSchemas";
import MessageContainer from "./MessageContainer";

const Excel: React.FC = () => {
  
  const {
    handleSubmit,
    initValues,
    cancelForm,
    //selectedReports,
    loading
  } = useExcelForm();
  return (
    <Formik
    initialValues={initValues}
    onSubmit={handleSubmit}
    //validationSchema={validationSchemaReports}
  >
    {({ handleSubmit }) => (
      <Form translate="yes" onSubmit={handleSubmit}>
        <Stack>
        <NewFormField name="dateFrom" label="Data początkowa do wygenerowania raportu" type="date"/>
        <NewFormField name="dateTo" label="Data końcowa do wygenerowania raportu" type="date"/>
        <NewFormBtns
                cancelForm={cancelForm}
                submitBtnText={"Pobierz"}
                disabled={loading}
                positionAbsolute={true}
              />
        </Stack>
        {/* { loading ? <ActivityIndicator/> : <MessageContainer/> }  */}
      </Form>
    )}
  </Formik>
  );
};

export default Excel;