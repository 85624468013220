


export enum NavigationPath {
    HOME = "/",
    AUTH = "/authorization",
    NEW_PASS = "/setnewPassword",
    INACTIVE_ACCOUNT = "/inactive",
    
    //client
    DASHBOARD = "/client/dashboard",
    ORDERS = "/client/orders",
    RECEPTION_PLACES = "/client/receptionPlaces",
    //orders
    ALL_ORDERS="/orders/all",
    NEW_ORDERS ="/orders/new",
    ACCEPTED_ORDERS="/orders/accepted",
    IN_PROGRESS_ORDERS ="/orders/in-progress",
    COMPLETED_ORDERS ="/orders/completed",
    CANCELED_ORDERS="/orders/canceled",
    //clients
    UNVERIFIED_CLIENTS="/clients/unverified",
    // NEW_CLIENTS="/clients/new",
    ACTIVE_CLIENTS="/clients/active",
    INACTIVE_CLIENTS="/clients/inactive",
    //config
    ORDER_TYPES = "/config/orderTypes",
    VEHICLES = "/config/vehicles",
    DRIVERS="/config/drivers",
    PRICING='/config/pricing',
    OPERATORS='/config/operators',
    CANCELLATION_REASONS='/config/cancellationReasons',
    ADMINS=`/config/admins`,
    //temp
    TEMP="/TEMP"
}



export enum BreadcrumbPath {
    //orders
    ALL_ORDERS="zamówienia/wszystkie",
    NEW_ORDERS ="zamówienia/nowe",
    ACCEPTED_ORDERS="zamówienia/zaakceptowane",
    IN_PROGRESS_ORDERS ="zamówienia/w trakcie realizacji",
    COMPLETED_ORDERS ="zamówienia/zrealizowane",
    CANCELED_ORDERS="zamówienia/anulowane",
    //clients
    UNVERIFIED_CLIENTS="klienci/bez weryfikacji",
    NEW_CLIENTS="klienci/nowi",
    ACTIVE_CLIENTS="klienci/aktywni",
    INACTIVE_CLIENTS="klienci/nieaktywni",
    //config
    ORDER_TYPES="ustawienia/typy zamówień",
    VEHICLES = "ustawienia/flota",
    DRIVERS="ustawienia/Kierowcy",
    PRICING="ustawienia/cennik",
    OPERATORS='ustawienia/operatorzy',
    CANCELLATION_REASONS='ustawienia/powody anulowania',
    ADMINS=`ustawienia/administratorzy`
}

export const PathMap = new Map<string, BreadcrumbPath>([
    //orders
    [NavigationPath.ALL_ORDERS, BreadcrumbPath.ALL_ORDERS],
    [NavigationPath.NEW_ORDERS, BreadcrumbPath.NEW_ORDERS],
    [NavigationPath.ACCEPTED_ORDERS, BreadcrumbPath.ACCEPTED_ORDERS],
    [NavigationPath.IN_PROGRESS_ORDERS, BreadcrumbPath.IN_PROGRESS_ORDERS],
    [NavigationPath.COMPLETED_ORDERS, BreadcrumbPath.COMPLETED_ORDERS],
    [NavigationPath.CANCELED_ORDERS, BreadcrumbPath.CANCELED_ORDERS],
    //clients
    [NavigationPath.UNVERIFIED_CLIENTS, BreadcrumbPath.UNVERIFIED_CLIENTS],
    // [NavigationPath.NEW_CLIENTS, BreadcrumbPath.NEW_CLIENTS],
    [NavigationPath.ACTIVE_CLIENTS, BreadcrumbPath.ACTIVE_CLIENTS],
    [NavigationPath.INACTIVE_CLIENTS, BreadcrumbPath.INACTIVE_CLIENTS],
    //config
    [NavigationPath.ORDER_TYPES, BreadcrumbPath.ORDER_TYPES],
    [NavigationPath.VEHICLES, BreadcrumbPath.VEHICLES],
    [NavigationPath.DRIVERS, BreadcrumbPath.DRIVERS],
    [NavigationPath.PRICING, BreadcrumbPath.PRICING],
    [NavigationPath.OPERATORS, BreadcrumbPath.OPERATORS],
    [NavigationPath.CANCELLATION_REASONS, BreadcrumbPath.CANCELLATION_REASONS],
    [NavigationPath.ADMINS, BreadcrumbPath.ADMINS],
])