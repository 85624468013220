import jwtDecode from "jwt-decode";
import authentication from "react-azure-b2c";
import { useHistory, useLocation } from "react-router-dom";
import { NavigationPath } from "../NavigationPath";

type IDecoded = {
  role: string;
  exp: number
};

export enum Roles {
 ADMIN = "ADMIN",
 CLIENT = "CLIENT"
}

export const authorize = () => {

  const token = authentication.getAccessToken().accessToken;
  const isActivated = sessionStorage.getItem("isActivated") === "true";
  const hasAllRequiredData = sessionStorage.getItem("hasAllRequiredData") === "true";
  let role: string | null = "";
  let expired: boolean = false
  if (token) {
    const decoded: IDecoded = jwtDecode(token);
    role = "ADMIN"
    if (sessionStorage.getItem('role') !== "") {
      role = sessionStorage.getItem('role');
    }      
    const dateNow = new Date()
    if(decoded.exp * 1000 < dateNow.getTime()){
      expired = true
      authentication.signOut();
    }
    
  }

  return {
    token,
    role,
    expired,
    isActivated,
    hasAllRequiredData
  };
};
