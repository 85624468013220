import React from "react";
import { Formik, Form } from "formik";
import NewFormField from "../../common/layout/form/NewFormField";
import FormDropDown from "../../common/layout/form/FormDropDown";
import NewFormBtns from "../../common/layout/form/NewFormBtns";
import Btn from "../../common/layout/form/Btn";
import ActivityIndicator from "../../common/layout/ActivityIndicator";
import { useReportForm } from "../hooks/useReportForm";
import { Stack, Pivot, PivotItem, } from "office-ui-fabric-react";
import { validationSchemaReports } from "../actions/validationSchemas";
import MessageContainer from "./MessageContainer";
import { Icon, IPivotItemProps } from '@fluentui/react';

export interface ReportFormProps {
  users: any[];
  userinfo: any;
}

function _customRenderer(
  link?: IPivotItemProps,
  defaultRenderer?: (link?: IPivotItemProps) => JSX.Element | null,
): JSX.Element | null {
  if (!link || !defaultRenderer) {
    return null;
  }

  return (
    <span style={{ flex: '0 0 100%' }}>
      {defaultRenderer({ ...link, itemIcon: undefined })}
      <Icon iconName={link.itemIcon} style={{ color: 'red', marginLeft: '3px' }} />
    </span>
  );
}

const ReportForm: React.FC<ReportFormProps> = ({ users, userinfo }) => {
  const {
    handleSubmit,
    initValues,
    cancelForm,
    selectedReports,
    loading,
    isRecordBold
  } = useReportForm(users, userinfo);

  const halfWidthStyle = {
    width: "50%",
    paddingRight: "50%",
    margin: "0px",
    paddingBottom: 20
  }

  const bottomPadding = {
    paddingBottom: 20
  }
  const [selectedKey, setSelectedKey] = React.useState(0);

  const MovetoCurrentPivot = (item: any) => {
    setSelectedKey(item.props.itemKey);
  }

  return (
    <Formik
      initialValues={initValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchemaReports}
      validateOnChange={false}
    //validateOnBlur={false}
    >
      {({ handleSubmit, errors }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack >
            <div>
              <div style={{ float: "left", zIndex: 2 }}>
              </div>
              <div style={{ zIndex: 1 }}>
                <Pivot onLinkClick={MovetoCurrentPivot} aria-label="Basic Pivot Example" selectedKey={String(selectedKey)} style={{ zIndex: 1 }}>
                  <PivotItem headerText="Informacje podstawowe" itemIcon={errors.leadingPersonPhoneNumber || errors.proceedingType || errors.bankStatus ? "AlertSolid" : undefined} onRenderItemLink={_customRenderer} itemKey="0">
                    <div style={halfWidthStyle}>
                      <FormDropDown
                        name="leadingPersonPhoneNumber"
                        options={users}
                        headerText="Osoba prowadząca"
                        required={false}
                        bold={isRecordBold("leadingpersonobjectid")}
                      />
                      <FormDropDown name="proceedingType"
                        required={false}
                        options={[
                          { key: "cywilne", text: "cywilne" },
                          { key: "karne", text: "karne" },
                          { key: "administracyjne", text: "administracyjne" }
                        ]} 
                        headerText="Rodzaj postępowania" 
                        bold={isRecordBold("proceedingType")}
                        />
                      <FormDropDown name="bankStatus"
                        required={false}
                        options={[
                          { key: "powód", text: "powód" },
                          { key: "pozwany", text: "pozwany" },
                          { key: "wnioskodawca", text: "wnioskodawca" },
                          { key: "uczestnik", text: "uczestnik" },
                          { key: "pokrzywdzony", text: "pokrzywdzony" },
                          { key: "podejrzany", text: "podejrzany" },
                          { key: "oskarżony", text: "oskarżony" },
                          { key: "oskarżyciel posiłkowy", text: "oskarżyciel posiłkowy" }
                        ]} 
                        headerText="Status SGB jako strony" 
                        bold={isRecordBold("bankStatus")}
                        />
                    </div>
                    <div>
                      <NewFormField name="opposingParty" label="Oznaczenie/nazwa strony przeciwnej/innych uczestników postępowania" bold={isRecordBold("proceedingType")} />
                      <NewFormField name="court" label="Sąd (nazwa i miejscowość)" bold={isRecordBold("court")} />
                    </div>
                    <div style={halfWidthStyle}>
                      <NewFormField name="caseFileSignature" label="Sygnatura akt sprawy" bold={isRecordBold("caseFileSignature")} />
                      <NewFormField name="sueDate" label="Data pozwu/pisma wszczynającego postępowanie" type="date" bold={isRecordBold("sueDate")} />
                    </div>
                  </PivotItem>
                  <PivotItem headerText="Przedmiot sprawy" itemIcon={errors.subjectMatter ? "AlertSolid" : undefined} onRenderItemLink={_customRenderer} itemKey="1" >
                    <div style={bottomPadding} />
                    <NewFormField name="subjectMatter" label="Przedmiot sprawy" required={false} bold={isRecordBold("subjectMatter")} />
                    <NewFormField name="caseCourse" label="Przebieg sprawy" multiline rows={3} bold={isRecordBold("caseCourse")}/>
                    <div style={halfWidthStyle}>
                      <FormDropDown name="hasFinished"
                        required={false}
                        options={[
                          { key: "Tak", text: "Tak", },
                          { key: "Nie", text: "Nie" }
                        ]} 
                        headerText="Sprawa zakończona" 
                        bold={isRecordBold("hasFinished")}
                        />
                    </div>
                    <NewFormField name="riskAssesment" label="Ocena sprawy i ryzyka" type="textarea" bold={isRecordBold("riskAssesment")} />
                    <div style={halfWidthStyle}>
                      <NewFormField name="subjectMattersValue" label="Wartość przedmiotu sporu - w PLN" type="number" bold={isRecordBold("subjectMattersValue")} />
                      <FormDropDown name="conductRisk"
                        required={false}
                        options={[
                          { key: "Tak", text: "Tak", },
                          { key: "Nie", text: "Nie" }
                        ]} 
                        headerText="Czy sprawa powiązana z Conduct Risk?" 
                        bold={isRecordBold("conductRisk")}
                        />
                    </div>
                  </PivotItem>
                  <PivotItem headerText="Koszty i opłaty" itemKey="2">
                    <div style={bottomPadding} />
                    <div style={halfWidthStyle}>
                      <NewFormField name="legalFees" label="Opłaty sądowe - w PLN" type="number" bold={isRecordBold("legalFees")} />
                      <NewFormField name="representationCost" label="Koszty zastępstwa procesowego" type="number" bold={isRecordBold("representationCost")} />
                      <NewFormField name="otherCosts" label="Inne koszty - w PLN" type="number" bold={isRecordBold("otherCosts")} />
                      <NewFormField name="claimValue" label="Wartość roszczenia" type="number" bold={isRecordBold("claimValue")} />
                      <FormDropDown name="losingProbability"
                        required={false}
                        options={[
                          { key: "Bank raczej wygra", text: "Bank raczej wygra" },
                          { key: "Bank raczej przegra", text: "Bank raczej przegra" }
                        ]}
                        headerText="Prawdopodobieństwo przegrania sprawy przez Bank"
                        bold={isRecordBold("losingProbability")}
                      />
                      <FormDropDown name="endsThisYear"
                        required={false}
                        options={[
                          { key: "Raczej tak", text: "Raczej tak" },
                          { key: "Raczej nie", text: "Raczej nie" }
                        ]}
                        headerText="Czy sprawa zakończy się w bieżącym roku kalendarzowym?"
                        bold={isRecordBold("endsThisYear")}
                      />
                    </div>
                  </PivotItem>
                </Pivot>
              </div>
            </div>
          </Stack>
          <NewFormBtns
            cancelForm={cancelForm}
            submitBtnText={selectedReports[0] ? "Zapisz" : "Dodaj"}
            disabled={loading}
            positionAbsolute={true}
          />
          {loading ? <ActivityIndicator /> : <MessageContainer />}
        </Form>
      )}
    </Formik>
  );
};
export default ReportForm;