import React from 'react'
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";

import { Dialog, DialogType, DialogFooter } from '@fluentui/react/lib/Dialog';
import { PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { hiddenContentStyle, mergeStyles } from '@fluentui/react/lib/Styling';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { ContextualMenu } from '@fluentui/react/lib/ContextualMenu';
import { useId, useBoolean } from '@fluentui/react-hooks';
import { toggleReportsPanel, removeData, setSelectedReports, toggleRemoveConfirmation } from "../../../config/redux/reports/reportActions"
import { useReportForm } from "../hooks/useReportForm";

const dialogStyles = { main: { maxWidth: 450 } };
const dragOptions = {
  moveMenuItemText: 'Move',
  closeMenuItemText: 'Close',
  menu: ContextualMenu,
  keepInBounds: true,
};
const screenReaderOnly = mergeStyles(hiddenContentStyle);
const dialogContentProps = {
  type: DialogType.normal,
  title: 'Uwaga',
  closeButtonAriaLabel: 'Close',
  subText: 'Czy na pewno chcesz usunać tę pozycję?',
};


const ReportDeleteConfirmation: React.FC = () => {
  
    const {
    removeReport
    } = useReportForm([], undefined)
  
    const dispatch = useDispatch();  
    const { removeConfirmation, selectedReports } = useSelector(
        (state: RootStateOrAny) => state.reports
    );
     
    const props = {
    titleAriaId: "labelId",
    subtitleAriaId: "subTextId",
    isBlocking: false,
    styles: dialogStyles,
    dragOptions: undefined,
    }
    const cancelRemove = () => {
    dispatch(toggleRemoveConfirmation(false))
    }

    const removeReports = () => {
    removeReport()
    }

    return (
        <>
  
        <Dialog
          hidden={!removeConfirmation}
          onDismiss={cancelRemove}
          dialogContentProps={dialogContentProps}
          modalProps={props}
        >
          <DialogFooter>
            <PrimaryButton onClick={removeReport} text="Usuń" />
            <DefaultButton onClick={cancelRemove} text="Anuluj" />
          </DialogFooter>
        </Dialog>
      </>
    )
}

export default ReportDeleteConfirmation
