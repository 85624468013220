import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import {
    DetailsListLayoutMode,
    Selection,
    IColumn,
    SelectionMode,
} from "office-ui-fabric-react/lib/DetailsList";
import { MarqueeSelection } from "office-ui-fabric-react/lib/MarqueeSelection";
import { ShimmeredDetailsList, Stack } from "office-ui-fabric-react";
import { columns } from "./ReportColumns"
import {
    IProps,
    IReportsListState,
    IStateProps,
    IDispatchProps,
} from "../models/ReportsList.types";
import { footer, listS, listContainerS } from "../styles/ReportsList.styles";
import Pagination from "../providers/Pagination";
import { IRenderProps } from "../providers/Pagination.types";
import {
    setActiveReport,
    setSelectedReports,
} from "../../../config/redux/reports/reportActions";
import { toggleReportsPanel } from "../../../config/redux/reports/reportActions";
import { IReport } from "../../../config/redux/reports/reportsAction.types";
import { togglefiltartion, sort } from "../../../config/redux/filters/filterActions";
import { DetailsRow, IDetailsColumnRenderTooltipProps, IDetailsHeaderProps, IDetailsListProps, IDetailsRowStyles, IRenderFunction, mergeStyles, mergeStyleSets, ScrollablePane, ScrollbarVisibility, Sticky, StickyPositionType, TextField, TooltipHost } from "@fluentui/react";

function _renderItemColumn(item?: any, index?: number, column?: IColumn) {
    const fieldContent = column ? item[column.fieldName as keyof any] as string : [];

    if (item.recentChangedColumnsList.includes(column?.key.toLowerCase())
        || (item.recentChangedColumnsList.includes("leadingpersonobjectid") && column?.key == 'leadingPerson')) {
        return <span className={mergeStyles({ color: '#000', fontWeight: 600 })}>{fieldContent}</span>
    }
    else {
        return fieldContent
    }
}

const onRenderDetailsHeader: IRenderFunction<IDetailsHeaderProps> = (props, defaultRender) => {
    if (!props) {
        return null;
    }
    const onRenderColumnHeaderTooltip: IRenderFunction<IDetailsColumnRenderTooltipProps> = tooltipHostProps => (
        <TooltipHost {...tooltipHostProps} />
    );
    return (
        <Sticky stickyPosition={StickyPositionType.Header} isScrollSynced>
            {defaultRender!({
                ...props,
                onRenderColumnHeaderTooltip,
            })}
        </Sticky>
    );
};

const classNames = mergeStyleSets({
    wrapper: {
        height: '88vh',
        position: 'relative',
        backgroundColor: 'white',
    },
});

class ReportListA extends React.Component<IProps, IReportsListState> {
    private _selection: Selection;
    private _columns: IColumn[];
    private _generatedColumns: IColumn[];
    private _onItemInvoked: (item?: any, index?: number, e?: Event) => void;
    private _onColumnHeaderClick: (ev?: React.MouseEvent<HTMLElement>, column?: IColumn) => void;
    protected _footerRef: React.RefObject<HTMLDivElement>;
    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
            if (props.item.canEdit == true) {
                // Every other row renders with a different background color
                customStyles.root = { backgroundColor: "#EEEEEE" };
            }

            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };


    constructor(props: IProps) {
        super(props);

        this._selection = new Selection({
            onSelectionChanged: () => {
                if (!this.props.reportPanelOpen) {
                    const items = this._getSelectedKeys();
                    const data = { selectedReports: [...items] };
                    props.dispatch(setSelectedReports([...items]));
                }
            },
        });

        this._footerRef = React.createRef();

        this._generatedColumns = columns;

        this._onItemInvoked = (item: any) => {
            props.dispatch(setActiveReport(item));
            props.dispatch(toggleReportsPanel({ open: true, edit: false }));
        };


        this._columns = [...this._generatedColumns];

        this._onColumnHeaderClick = (item: any, column: any) => {
            const newColumns: IColumn[] = this._columns.slice();
            const currColumn: IColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
            newColumns.forEach((newCol: IColumn) => {
                if (newCol === currColumn) {
                    currColumn.isSortedDescending = !currColumn.isSortedDescending;
                    currColumn.isSorted = true;
                } else {
                    newCol.isSorted = false;
                    newCol.isSortedDescending = true;
                }
            });
            props.dispatch(togglefiltartion({ filter: true }));
            props.dispatch(sort({ column: currColumn }));

            this.setState({
                _cols: newColumns,
            });

        };

        this.state = {
            items: [],
            loading: false,
            _cols: this._columns
        };
    }

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch(setSelectedReports(new Array<IReport>()));
    }

    componentDidUpdate(prevprops: any) {

        const {
            reloadReports,
            remove,
            selectedReports,
        } = this.props;
        if (reloadReports || remove) {
            this._selection.selectToKey("", true);
        }

        if (!selectedReports[0]) {
            this._selection.selectToKey("", true);
        }
    }

    public render(): JSX.Element {

        return (
            <>
                <Pagination
                    footerRef={this._footerRef}
                    render={({ data }: IRenderProps) => (
                        <>
                            <Stack styles={listContainerS}>
                                <MarqueeSelection selection={this._selection}>
                                    <ShimmeredDetailsList
                                        items={data}
                                        columns={this.state._cols}
                                        onRenderItemColumn={_renderItemColumn}
                                        setKey="items"
                                        layoutMode={DetailsListLayoutMode.justified}
                                        selection={this._selection}
                                        selectionMode={SelectionMode.single}
                                        selectionPreservedOnEmptyClick={false}
                                        onItemInvoked={this._onItemInvoked}
                                        usePageCache={false}
                                        styles={listS}
                                        onColumnHeaderClick={this._onColumnHeaderClick}
                                        enableShimmer={this.props.reloadReports}
                                        onRenderDetailsHeader={onRenderDetailsHeader}
                                        onRenderRow={this._onRenderRow}
                                    />
                                </MarqueeSelection>
                            </Stack>
                            <div className={footer} ref={this._footerRef} />
                        </>
                    )}
                />
            </>
        );
    }

    private _getSelectedKeys = () => {
        const keys = this._selection.getSelection();
        return keys;
    };
}

const mapStateToProps = (state: RootStateOrAny) => state.reports;

export default connect<IStateProps, IDispatchProps>(
    mapStateToProps
)(ReportListA);