import * as React from "react";
import {
  CommandBar,
} from "office-ui-fabric-react";
//import { comandBarS} from "../../styles/commandBar.styles"
import { IRenderFunction, IPanelProps } from "@fluentui/react";
//import { useReportsCommandBar} from "../hooks/useReportsCommandBar"
// import { usePricingCommandBar } from "../../hooks/settings/usePricingCommandBar";
import {
    ICommandBarStyles,
    getTheme,
  } from "office-ui-fabric-react";

const ReportPanelCommandBar: IRenderFunction<IPanelProps> = () => {
    const theme = getTheme();
    // const _items: ICommandBarItemProps[] = [
    //     {
    //       key: 'newItem',
    //       text: 'New',
    //       cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
    //       iconProps: { iconName: 'Add' },
    //       subMenuProps: {
    //         items: [
    //           {
    //             key: 'emailMessage',
    //             text: 'Email message',
    //             iconProps: { iconName: 'Mail' },
    //             ['data-automation-id']: 'newEmailButton', // optional
    //           },
    //           {
    //             key: 'calendarEvent',
    //             text: 'Calendar event',
    //             iconProps: { iconName: 'Calendar' },
    //           },
    //         ],
    //       },
    //     },
    //     {
    //       key: 'upload',
    //       text: 'Upload',
    //       iconProps: { iconName: 'Upload' },
    //       href: 'https://developer.microsoft.com/en-us/fluentui',
    //     },
    //     {
    //       key: 'share',
    //       text: 'Share',
    //       iconProps: { iconName: 'Share' },
    //       onClick: () => console.log('Share'),
    //     },
    //     {
    //       key: 'download',
    //       text: 'Download',
    //       iconProps: { iconName: 'Download' },
    //       onClick: () => console.log('Download'),
    //     },
    //   ];
    const comandBarS: ICommandBarStyles = {
      root: {
        position: "fixed",
        //top:,
        //left: 0,
        //height: "80px",
        //width: "100%",
        //zIndex: 1,
        //margin: '5px', 
        //height: 'auto', 
        //width: '100%',
        //position: "inherit",
        //top: 0,
        //left: 0,
        //right: 50,
        //height: "80px",
        width: "100%",
        //zIndex: 10,
        borderBottom: `2px solid ${theme.palette.neutralLighter}`,
      },
    };
  
  //const {items} = useReportsCommandBar()
  return <CommandBar styles={comandBarS} items={[]}/>;
}
 
export default ReportPanelCommandBar;