import React, { CSSProperties, useCallback, useEffect } from "react";
import { Dropdown, IDropdownOption } from "office-ui-fabric-react/lib/Dropdown";
import { IMessageBarStyles, MessageBar, MessageBarType, Text } from "office-ui-fabric-react";
import { useField } from "formik";

import { headerS, wrapper } from "../../styles/formDropDown.styles";
import { useEffectAfterMount } from "../../hooks/useEffectAfterMount";


const errorMessageS: IMessageBarStyles = {
  root: {
    marginTop: "15px",
    width: "100%",
  },
};

export interface FormDropDownProps {
  name: string;
  headerText: string;
  options: Array<any>;
  placeholder?: string;
  onChange?: any
  onChangeEffect?: any,
  required?: boolean,
  bold? :boolean
}

const FormDropDown: React.FC<FormDropDownProps> = ({
  options,
  headerText,
  placeholder,
  name,
  onChange,
  onChangeEffect,
  required = true,
  bold= false  
}) => {
  const [field, { value, error }, { setValue }] = useField(name);

  const handleChange = useCallback(
    async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => {
      if (option) {
        setValue(option.key);
        if(onChange){
          onChange(option)
        } 
      }
    },
    [options]
  );

  useEffectAfterMount(() => {
    if(onChangeEffect){
      if(options){
        const item = options.find((option: any) => {
          if(option.value == value){
            return option
          }
        })
            onChangeEffect(item)
      }
    }


  },[value, options[0]])

  const errorStyle ={
    color: 'rgb(164, 38, 44)',
    fontSize: '12px',
    //fontWeight: '400',
  }

  const style: CSSProperties = {
    color: 'black',
    fontWeight: 600
}

  return (
    <div className={wrapper}>
      <Dropdown
        selectedKey={value ? value : ""}
        placeholder={placeholder}
        options={options}
        onChange={handleChange}
        label={headerText}
        required={required}
        style={bold ? style : undefined}
      />
      {error && (
        <p style={errorStyle}>
          {error}
        </p>

        // <MessageBar
        //   messageBarType={MessageBarType.error}
        //   isMultiline={false}
        //   dismissButtonAriaLabel="None"
        //   styles={errorMessageS}
          
        // >
        //   {error}
        // </MessageBar>
      )}
    </div>
  );
};

export default FormDropDown;
