import { reloadReports } from './../../../config/redux/reports/reportActions';
import * as React from "react";
import { connect, RootStateOrAny } from "react-redux";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { getReports } from "../../../connectors/reports/connectors/index";
import { removeData } from "../../../config/redux/reports/reportActions";
import { IPaginationState, IPaginationProps } from "./Pagination.types";
import { togglefiltartion } from "../../../config/redux/filters/filterActions";
// import { getParams } from "../actions/getParams";
import {parseReports} from "../actions/parseReports"

class Pagination extends React.Component<IPaginationProps, IPaginationState> {
  private _data: any = [];

  footerRef = React.createRef<HTMLDivElement>();

  state = {
    data: [],
    limit: 30,
    total: 0,
    page: 1,
    loading: false,
  };

  componentDidMount() {
    if (this.props.footerRef.current !== null) {
      this._observer.observe(this.props.footerRef.current);
    }

    this._setReports();
  }

  componentDidUpdate(prevProps: IPaginationProps) {

    const { remove, filter, reload, dispatch, searchPhrase, column} = this.props;

    if (remove) {
      this._remove();
      this._removeItems();
    }

    if(reload){
      this._setReports(1)
      dispatch(reloadReports({ reload: false }))
    }

    if (filter) {
      let columnKey : undefined | string = undefined;
      if (column) {
        columnKey = column.isSortedDescending ? "-" + column.key : column.key;
      }

      this.filterReports(searchPhrase, columnKey)
      dispatch(togglefiltartion({ filter: false }));
    }
  }

  componentWillUnmount() {
    if (this.props.footerRef.current !== null) {
      this._observer.unobserve(this.props.footerRef.current);
    }
  }

  private _handleObserver = (entry: any, observer: any) => {
    if (this.state.data.length < this.state.total) {
      this._setReports();
    }
  };

  private _observer = new IntersectionObserver(this._handleObserver, {
    root: null,
    rootMargin: "200px",
    threshold: 1,
  });

  private _remove = async () => {
    this.props.dispatch(removeData({ remove: false }));
  };

  private _removeItems = () => {
    const ids = this.props.selectedReports.map((item: any) => item.id)
    const data = _.remove(this.state.data, (item: any) => {
      if (!ids.includes(item.id)) {
        return item;
      }
    });

    this.setState({ data });
  };

  private filterReports = async (searchPhrase: string, sorts: undefined | string = undefined) => {
    const res = await this.fetchReports(1, searchPhrase, sorts);
    const reports = parseReports(res.data);
    this.setState((prevState: IPaginationState) => {
      return {
        data: [...reports],
        page: 1,
        total: res.data.total,
      };
    });
  }

  private _setReports = async (page?: 1) => {
    const res = await this.fetchReports(page ? page : this.state.page);
    const reports = parseReports(res.data);
    this.setState((prevState: IPaginationState) => {
      return {
        data: page ? [...reports] : [...prevState.data, ...reports],
        page: prevState.page + 1,
        total: 600,
      };
    });
  };

  
  private fetchReports = async (page: number, searchPhrase: undefined | string = undefined, sorts: undefined | string = undefined) => {
    const res = await getReports({
      page: page,
      pageSize: this.state.limit,
      filters: !searchPhrase ? undefined : "(subjectMatter|proceedingType|leadingPerson|bankStatus|OpposingParty|Court|CaseCourse|RiskAssesment)@=" + searchPhrase,
      sorts: sorts
    });

    return res;
  };

  public render() {
    const renderProps = {
      data: this.state.data,
    };

    return this.props.render(renderProps);
  }
}

const mapStateToProps = (state: RootStateOrAny) => {
  return {
    remove: state.reports.remove,
    // reload: state.reports.reloadReports,
    reload: state.reports.reloadReportsList,
    selectedReports: state.reports.selectedReports,
    filter: state.filtration.filter,
    searchPhrase: state.filtration.searchPhrase,
    column: state.filtration.column,
  };
};

export default connect(mapStateToProps)(withRouter(Pagination));