import api from "../../../config/axios/configAxios";
import { IReport } from "../models/index";

export interface IReportParams {
  page?: number;
  pageSize?: number;
  filters?: string;
  sorts?: string;
}

export const getReports = async (params?: IReportParams) =>
  api.get(`/caseitem`, { params });

export const deleteReport = async (reportId: string) =>
  api.delete(`/caseitem/${reportId}`);

export const addReport = async (data: IReport) => {
  api.post(`/caseitem`, JSON.stringify(data));
}
 
export const getUsersApi = async () =>{
  return api.get(`/users`)
}

export const getUserinfoApi = async () => {
  return api.get(`api/Authorization/userinfo`);
}
  //api.post(`/api/Admin/Users/AddDriver`, data);
  //console.log(`/api/Admin/Users/AddDriver ` +JSON.stringify(data));

export const editReport = async (data: IReport, driverId: string) =>
{
  api.put(`/caseitem`, JSON.stringify(data));
}
  //api.put(`/api/Admin/Users/drivers/${driverId}`, data);
 // console.log(`/api/Admin/Users/drivers/${driverId}` +JSON.stringify(data))