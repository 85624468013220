import React from "react";
import {
  DefaultButton,
  mergeStyles,
} from "office-ui-fabric-react";

export interface BtnsProps {
  cancelForm: () => void;
  resetBtnText?: string;
  positionAbsolute?: boolean
}


const btns = mergeStyles({
  position: "absolute",
  bottom: 30,
  left: 40,
});


const Btn: React.FC<BtnsProps> = ({
  resetBtnText,
  cancelForm,
  positionAbsolute = false
}) => {
  return (
    <div className={positionAbsolute ? btns : ""}>
      <DefaultButton
        text={resetBtnText ? resetBtnText : "Anuluj"}
        type="reset"
        onClick={cancelForm}
      />
    </div>
  );
};

export default Btn;