import { clearAlerts, setListMessage, setErrorAlert } from '../../../config/redux/alerts/alertsActions';
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import { FormikValues } from "formik";

import {
    setReport,
    addNewReport,
    toggleReportsPanel,
    reloadReports,
    removeData,
    toggleRemoveConfirmation
} from "../../../config/redux/reports/reportActions";
import { addReport, editReport } from "../../../connectors/reports/connectors/index";
import { IReport } from "../../../connectors/reports/models/index"
import { IResetForm } from "../../common/models/resetForm.type";
import { useState } from "react";

import moment from 'moment';

import { deleteReport } from "../../../connectors/reports/connectors";

function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export const useReportForm = (users: any[], userinfo: any) => {
    const [loading, setIsLoading] = useState(false)
    const { selectedReports, activeReport } = useSelector(
        (state: RootStateOrAny) => state.reports
    );

    const dispatch = useDispatch();

    // console.log(activeReport);

    let initSubject = {
        subjectMatter: "Dodaj sprawę"
    };
    if (selectedReports[0]) {
        initSubject = {
            subjectMatter: selectedReports[0].subjectMatter
        }
    }
    let initValues = {
        key: 0,
        leadingPersonPhoneNumber: userinfo ? userinfo.key : "",
        lawFirmName: "",
        proceedingType: "",
        bankStatus: "",
        opposingParty: "",
        court: "",
        caseFileSignature: "",
        sueDate: "",
        subjectMatter: "",
        caseCourse: "",
        riskAssesment: "",
        hasFinished: "",
        subjectMattersValue: 0,
        conductRisk: "",
        legalFees: 0,
        representationCost: 0,
        otherCosts: 0,
        claimValue: 0,
        losingProbability: "",
        endsThisYear: "",
        canEdit: true,
        leadingPerson: "",
        recentChangedColumnsList: []
    };
    if (users.length > 0) {
        //var leadingPersonPhoneNumber = users.find(x => x.text == selectedReports[0].leadingPersonPhoneNumber).id
        //console.log("sel " + selectedReports[0].leadingPersonPhoneNumber)
        //console.log({users});
        //console.log(leadingPersonPhoneNumber)
        if (selectedReports[0]) {
            initValues = {
                canEdit: selectedReports[0].canEdit,
                key: selectedReports[0].key,
                lawFirmName: selectedReports[0].lawFirmName,
                leadingPersonPhoneNumber: selectedReports[0].leadingPersonPhoneNumber == undefined ? "" : selectedReports[0].leadingPersonPhoneNumber,
                leadingPerson: selectedReports[0].leadingPersonPhoneNumber,
                proceedingType: selectedReports[0].proceedingType,
                bankStatus: selectedReports[0].bankStatus,
                opposingParty: selectedReports[0].opposingParty,
                court: selectedReports[0].court,
                caseFileSignature: selectedReports[0].caseFileSignature,
                sueDate: selectedReports[0].sueDate,
                subjectMatter: selectedReports[0].subjectMatter,
                caseCourse: selectedReports[0].caseCourse,
                hasFinished: selectedReports[0].hasFinished,
                riskAssesment: selectedReports[0].riskAssesment,
                subjectMattersValue: selectedReports[0].subjectMattersValue,
                conductRisk: selectedReports[0].conductRisk == "Tak" ? "Tak" : "Nie",
                legalFees: selectedReports[0].legalFees,
                representationCost: selectedReports[0].representationCost,
                otherCosts: selectedReports[0].otherCosts,
                claimValue: selectedReports[0].claimValue,
                losingProbability: selectedReports[0].losingProbability === "Bank raczej wygra" ? "Bank raczej wygra" : "Bank raczej przegra",
                endsThisYear: selectedReports[0].endsThisYear === "Raczej tak" ? "Raczej tak" : "Raczej nie",
                recentChangedColumnsList: selectedReports[0].recentChangedColumnsList
            };
        }
    }

    const isRecordBold = (data: string) => {
        var ret = false;
        if (activeReport == undefined || activeReport.recentChangedColumnsList == undefined) return ret;
        if (activeReport.recentChangedColumnsList.map((x: string) => {
            if (x == data.toLowerCase())
                ret = true;
        })) {
        }
        return ret;
    }

    const add = async (data: IReport) => {
        const res = await addReport(data);
    };

    const edit = async (data: IReport) => {
        editReport(data, selectedReports[0].id);
    };

    const handleSubmit = async (
        values: FormikValues,
        { resetForm }: IResetForm
    ) => {

        setIsLoading(true)
        //console.log(values);
        //delay(50000); 
        // var users = getUsers();
        //console.log(values.sueDate);
        //console.log(users);
        //console.log(values.leadingPersonPhoneNumber);
        //var leadingPersonPhoneNumber = users.find(x => x.key == values.leadingPersonPhoneNumber).id
        const body = {
            Id: values.key,// key : item.Id,
            LeadingPersonObjectId: values.leadingPersonPhoneNumber == "" ? "" : users.find(x => x.key == values.leadingPersonPhoneNumber).id,
            //LeadingPersonObjectId : leadingPersonPhoneNumber,
            LawFirmName: values.lawFirmName,
            ProceedingType: values.proceedingType,
            BankStatus: values.bankStatus,
            OpposingParty: values.opposingParty,
            Court: values.court,
            CaseFileSignature: values.caseFileSignature,
            SueDate: moment(values.sueDate).format('YYYY-MM-DD') == "Invalid date" ? null : moment(values.sueDate).format('YYYY-MM-DD'),
            //moment(item.sueDate).format('YYYY-DD-MM'),
            SubjectMatter: values.subjectMatter,
            CaseCourse: values.caseCourse,
            HasFinished: values.hasFinished == "Tak" ? true : false,
            RiskAssesment: values.riskAssesment,
            SubjectMattersValue: values.subjectMattersValue,
            ConductRisk: values.conductRisk == "Tak" ? true : false,
            LegalFees: values.legalFees,
            RepresentationCost: values.representationCost,
            OtherCosts: values.otherCosts,
            ClaimValue: values.claimValue,
            LosingProbability: values.losingProbability,
            EndsThisYear: values.endsThisYear,
            NegativeEffect: false,
            //leadingPerson : values.leadingPerson
        };
        try {
            // dispatch(clearAlerts())
            selectedReports.length >= 1 ? await edit(body) : await add(body);
            await delay(500); // potrzebny delay żeby się dane odśiweżyły, do ogarnięcia dlaczego
            setIsLoading(false)
            dispatch(setReport({}));
            //dispatch(addNewReport({ add: true }));
            dispatch(reloadReports({ reload: true }))
            window.location.reload(); //temp
            //dispatch(togglefiltartion({ filter: true }));
            resetForm();
            dispatch(toggleReportsPanel({ open: false, edit: false }));
            dispatch(setListMessage("Operacja przebiegła pomyślnie"))
        } catch (err) {
            setIsLoading(false)
            dispatch(setErrorAlert("Użytkownik o podanym adresie email już istnieje"))
        }
    };

    const cancelForm = () => {
        dispatch(toggleReportsPanel({ open: false, edit: false }));
        dispatch(clearAlerts())
    };

    const message = "Operacja przebiegła pomyślnie"
    const removeReport = async () => {
        try {
            //await deleteReport(selectedReports[0].id); // do przerobienia przy multiselecie
            await Promise.all(
                selectedReports.map(async (report: any) => {
                    deleteReport(report.id);
                })
            )
            dispatch(removeData({ remove: true }));
            dispatch(toggleRemoveConfirmation(false))
            dispatch(setListMessage(message))
        } catch (err) { }
    };

    return {
        initSubject,
        handleSubmit,
        initValues,
        cancelForm,
        selectedReports,
        loading,
        removeReport,
        isRecordBold
    };
};