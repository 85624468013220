import React, { useState } from "react"
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Panel } from "office-ui-fabric-react";

import { toggleReportsPanel } from "../../../config/redux/reports/reportActions";
import ReportForm from "./ReportForm";

import { panelS } from "../../common/styles/panelStyles"
import ReportPanelCommandBar from "../../../features/commandBar/components/report/ReportPanelCommandBar"

import {
    IRenderFunction,
    IPanelProps,
    Stack,
    IStackStyles,
    getTheme
} from "office-ui-fabric-react";
import { useReportForm } from "../hooks/useReportForm";
import ReportShow from "./ReportShow";

export interface ReportFormProps {
    users: any[];
    userinfo: any;
}

const ReportPanel: React.SFC<ReportFormProps> = ({ users, userinfo }) => {
    const dispatch = useDispatch();
    const { reportPanelOpen, reportEditPanel, activeReport } = useSelector(
        (state: RootStateOrAny) => state.reports
    );
    const theme = getTheme()

    const dissmisPanel = () => {
        dispatch(toggleReportsPanel({ open: false, edit: false }));
    };

    const navS: IStackStyles = {
        root: {
            maxWidth: "100%",
            width: "100%",
            borderBottom: `2px solid ${theme.palette.neutralLighter}`,
        },
    };

    const onRenderNavigationContent: IRenderFunction<IPanelProps> = ((props, defaultRender) => (
        <Stack horizontal styles={navS} horizontalAlign="space-between">
            <ReportPanelCommandBar />
            {defaultRender!(props)}
        </Stack>
    ));

    return (

        <Panel
            headerText={activeReport ? activeReport.subjectMatter : "Dodaj sprawę"}
            isOpen={reportPanelOpen}
            onRenderNavigationContent={onRenderNavigationContent}
            closeButtonAriaLabel="Close"
            type={3}
            onDismiss={dissmisPanel}
            isLightDismiss={false}
            isHiddenOnDismiss={false}
            isFooterAtBottom={true}
            styles={panelS}
            onOuterClick={() => { }}
        >
            {reportEditPanel ?
                <ReportForm
                    users={users}
                    userinfo={userinfo} />
                :
                <ReportShow
                    users={users}
                    userinfo={userinfo}
                />
            }

        </Panel>
    );
}

export default ReportPanel;