import { IExcelAction} from './excelAction.types'
import { ExcelActions} from './excelAction.enum'

const initState = {
    reportExcelOpen: false,
  };
  
  export const excelReducer = (state = initState, action: IExcelAction) => {
    const { type, payload } = action;

    switch (type) {
        case ExcelActions.TOGGLE_EXCEL_PANEL:
            return { ...state, reportPanelOpen: payload.open};
        default:
            return state;
    }
};