import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./config/routing/Routing";
import store from "./config/redux/store";
import { Provider } from "react-redux";
import {QueryCache, ReactQueryCacheProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query-devtools'

const queryCache = new QueryCache()

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <Router>
          <Routing />
        </Router>
      </ReactQueryCacheProvider>
    </Provider>
  );
};
