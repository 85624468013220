import React, { CSSProperties } from "react";
import { useField } from "formik";
import { TextField } from "office-ui-fabric-react";

import { textfieldS } from "../../styles/formField.styles";

export interface NewFormFieldProps {
    name: string;
    label: string;
    type?: string;
    placeholder?: string;
    disabled?: boolean;
    required?: boolean;
    multiline?: boolean;
    rows?: number;
    bold?: boolean
}

const NewFormField: React.FC<NewFormFieldProps> = ({
    type,
    name,
    label,
    placeholder,
    disabled,
    required = false,
    multiline = false,
    rows = 1,
    bold = false
}) => {
    const [field, { error, touched }, { }] = useField(name);

    const style: CSSProperties = {
        color: 'black',
        fontWeight: 600
    }

    return (
        <TextField
            type={type ? type : "text"}
            label={label}
            placeholder={placeholder}
            errorMessage={touched ? error : ""}
            required={required}
            disabled={disabled}
            {...field}
            styles={textfieldS}
            multiline={multiline}
            rows={rows}
            style={bold ? style : undefined}
        />
    );
};

export default NewFormField;