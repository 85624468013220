export const columns = [
    {
      key: "opposingParty",
      name: "Strona przeciwna",
      fieldName: "opposingParty",
      minWidth: 250,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "subjectMatter",
      name: "Przedmiot sprawy",
      fieldName: "subjectMatter",
      minWidth: 250,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "leadingPerson",
      name: "Osoba prowadząca",
      fieldName: "leadingPersonPhoneNumber",
      minWidth: 250,
      maxWidth: 300,
      isResizable: true,
    },
    {
      key: "proceedingType",
      name: "Rodzaj postępowania",
      fieldName: "proceedingType",
      minWidth: 250,
      maxWidth: 300,
      isResizable: true,
    },

    // {
    //   key: "bankStatus",
    //   name: "Status SGB jako strony",
    //   fieldName: "bankStatus",
    //   minWidth: 300,
    //   maxWidth: 350,
    //   isResizable: true,
    // },
    
  ];