import { IPanelStyles } from "office-ui-fabric-react";
import { Breakpoints } from "./utils/Breakpoints";

export const panelS: Partial<IPanelStyles> = {
    header: {
     marginTop: "5px",
     // marginLeft: "20px",
     //zIndex: 7,
      selectors: {
        [`@media ${Breakpoints.LARGE}`]: {
          marginLeft: "10px",
        },
      },
    },
  
    navigation: {
      position: "inherit",
      //top: "20px",
      //right: "100px",
      //zIndex: 3,
      //justifyContent: "center", 
      //width: "100%",
      left: 0,
      selectors: {
        [`@media ${Breakpoints.LARGE}`]: {
          top: "20px",
          right: "20px",
        },
      },
    },
  
    content: {
      marginTop: '0px',
      //position: "absolute",
      //zIndex : 5,
      minWidth: '50px',
      selectors: {
        [`@media (min-width: 375px)`]: {
          paddingLeft: "30px",
          paddingRight: "50px",
          paddingTop: "10px"
        },
      },
    },
  };