import * as React from "react";
import {
  Stack,
  IStackStyles,
  getTheme,
  SearchBox,
} from "office-ui-fabric-react";
import { reloadReports } from "../../../../config/redux/reports/reportActions"
import Logo from "./Logo";
import { useLocation } from "react-router-dom";
import Interface from "./Interface";
import SearchB from "./SearchBox";

const theme = getTheme();

const containerS: IStackStyles = {
  root: {
    backgroundColor: theme.palette.themePrimary,
    width: "100%",
    height: "50px",
    padding: "10px 25px",
    zIndex: 2000,
    position: "fixed",
    top: 0,
    left: 0,
    boxShadow: '0 5px 5px -5px #aaa',
  },
};

const sectionS: IStackStyles = {
  root: {
    width: "100%",
    marginRight: "70px",
  },
};

const Header: React.FC = () => {
  const location = useLocation()

  return (
    <Stack
      styles={containerS}
      verticalAlign="center"
      horizontal
      horizontalAlign="space-between"
    >
       <Stack.Item grow={15}>
      <Logo />
      </Stack.Item>
        <Stack.Item grow={2}>
        <SearchB reload={reloadReports}/>
        </Stack.Item>
        <Stack.Item  align="end">
      <Interface />
      </Stack.Item>
    </Stack>
  );
};

export default Header;
