import { IExcelPanel} from './excelAction.types'
import { ExcelActions} from './excelAction.enum'

export const toggleExcelPanel = (data: IExcelPanel) => {
    return {
        type: ExcelActions.TOGGLE_EXCEL_PANEL,
        payload: data
    }
}

