export enum ReportsActions{
    SET_REPORT = "setReport",
    SET_SELECTED_REPORTS = "setSelectedReports",
    TOGGLE_REPORTS_PANEL = "toggleReportssPanel",
    RELOAD_REPORTS = "reloadReports",
    CLEAR= "clearReports",
    ADD_REPORT="addReport",
    REMOVE_DATA="removeData",
    SET_ACTIVE_REPORT="setActiveReport",
    TOGGLE_REPORT_DOWNLOAD_LOADING = "reportDownloadLoading",
    TOGGLE_REMOVE_CONFIRMATION = "removeConfirmation"

}
