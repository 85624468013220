import axios, { AxiosResponse } from "axios";
import authentication from 'react-azure-b2c';
import {baseURL} from '../../config'
const api = () => {
  
  const defaultOptions = {
    
    baseURL: baseURL,
    //baseURL: 'https://insync-dev-k365-api.azurewebsites.net',
    //baseURL: 'https://localhost:44331/',
    headers: {
      'Content-Type': 'application/json',
    }
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = authentication.getAccessToken().accessToken;
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    config.headers['Access-Control-Max-Age'] = 0;

    return config;
  });

  // return response
  instance.interceptors.response.use((response: AxiosResponse) => response)

  return instance;
};

export default api()

