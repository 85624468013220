import * as React from "react";
import {
  Stack,
  IStackStyles,
  getTheme,
} from "office-ui-fabric-react";
import UserIcon from "./UserIcon";

const theme = getTheme();

export interface Notification {
  notificationType: number;
  count: number;
  name: string | undefined;
  dismissPanel: () => void;
}

const containerS: IStackStyles = {
  root: {
    height: "100%",
  },
};



const Interface: React.FC = () => {


  return (
    <Stack horizontal styles={containerS} verticalAlign="center">
      {/* <TooltipHost
        content="Powiadomienia"
        delay={TooltipDelay.zero}
        id={"1"}
        directionalHint={DirectionalHint.bottomCenter}
      >
        <div onClick={!isOpen || !type ? () => exe(true) : dismissPanel} className={isOpen && type ? iconContainerActive : iconContainer}>
          <FontIcon iconName="Ringer" className={isOpen && type ? iconClassActive : iconClass} />
          {!(isOpen && type)  ? <FontIcon iconName="CircleFill" className={iconClassNotification} /> : null}
          {!(isOpen && type)  ? <Text className={iconClassNotificationText}> {notificationCount}</Text> : null}
        </div>
      </TooltipHost>
      <TooltipHost
        content="Ustawienia"
        delay={TooltipDelay.zero}
        id={"2"}
        directionalHint={DirectionalHint.bottomCenter}
      >
        <NotificationPanel type={type} dismissPanel={dismissPanel} isOpen={isOpen} />

        <div onClick={!isOpen || type ? () => exe(false) : dismissPanel} className={isOpen && !type ? iconContainerActive : iconContainer}>
          <FontIcon iconName="Settings" className={isOpen && !type ? iconClassActive : iconClass} />
        </div>
      </TooltipHost> */}
      <UserIcon />
    </Stack>
  );
};

export default Interface;
