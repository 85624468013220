import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { toggleReportsPanel, removeData, setSelectedReports, toggleRemoveConfirmation, setActiveReport } from "../../../../config/redux/reports/reportActions";
import { setListMessage } from './../../../../config/redux/alerts/alertsActions';
import { checkQuantity } from "../../action/checkQuantity";
import { generateItems } from "../../action/generateReportsCommands"
import { deleteReport } from "../../../../connectors/reports/connectors";
import { useExcelForm } from "../../../reports/hooks/useExcelForm";
import { useReportForm } from "../../../reports/hooks/useReportForm";

const message = "Operacja przebiegła pomyślnie"

export const useReportsCommandBar = () => {
    const dispatch = useDispatch();
    const { selectedReports } = useSelector(
        (state: RootStateOrAny) => state.reports
    );

    const {
        downloadReport
    } = useExcelForm();

    const openPanelToAddReport = () => {
        selectedReports.splice(0, selectedReports.length)
        dispatch(toggleReportsPanel({ open: true, edit: true }))
    }

    const openPanelToShowReport = () => {
        dispatch(setActiveReport(selectedReports[0]));
        dispatch(toggleReportsPanel({ open: true, edit: false }))
    }

    const openPanelToEditReport = () => {
        dispatch(setActiveReport(selectedReports[0]));
        dispatch(toggleReportsPanel({ open: true, edit: true }))
    }

    const removeReport = () => {
        dispatch(toggleRemoveConfirmation(true))
    }

    const openExcelPanel = () => {
        downloadReport()
    }

    const actions = {
        openPanelToAddReport,
        openPanelToEditReport,
        openPanelToShowReport,
        removeReport,
        openExcelPanel
    }

    let canEdit = true;
    if (selectedReports.length == 1) {
        canEdit = selectedReports[0].canEdit
    }
    else if (selectedReports.length > 1) {
        for (let i = 0; i < selectedReports.length; i++) {
            if (selectedReports[i].canEdit == false) {
                canEdit = false
            }
        }
    }
    const quantity = checkQuantity(selectedReports.length);
    const items = generateItems(quantity, actions, canEdit);

    return {
        items,
    };
};

// function reportDeleteConfirmation(arg0: {}): any {
//   throw new Error("Function not implemented.");
// }
