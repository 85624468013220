import {
  Callout,
  DefaultButton,
  IPersonaSharedProps,
  IPersonaStyles,
  Persona,
  PersonaInitialsColor,
  PersonaSize,
  PrimaryButton,
  Stack,
  Text,
} from "office-ui-fabric-react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { NavigationPath } from "../../../../config/routing/NavigationPath";
// import { subTitleS } from "../../../orders/styles/detailTxt.style";
import { useUserData } from "../../hooks/useUserData";
import { btnS, btnsContainer, styles, subtitleContainer } from "../../styles/userCallout.styles";
import authentication from "react-azure-b2c";

const personaS: Partial<IPersonaStyles> = {
  root: {
    cursor: "pointer",
  },
};
export interface UserIconProps {}

const UserIcon: React.FC<UserIconProps> = () => {
  const [isCalloutVisible, setIsCalloutVisible] = useState(false);
  const history = useHistory()
  const dispatch = useDispatch()
  const { user } = useUserData();

  const handleCalloutVisibility = () => {
    setIsCalloutVisible(!isCalloutVisible);
  };

  // const openSelfEditPanel = () => {
  //   dispatch(toggleSelfEditPanel(true))
  // }

  const logout = () => {
    sessionStorage.removeItem('role');
    sessionStorage.removeItem('isActivated');
    sessionStorage.removeItem('hasAllRequiredData');
    authentication.signOut();
  }

  return (
    <>
      <div className={styles.buttonArea} style={{paddingRight: "20px"}}>
        <Persona
          styles={personaS}
          size={PersonaSize.size28}
          initialsColor={PersonaInitialsColor.lightGreen}
          text={user ? user.name : ""}
          hidePersonaDetails={true}
          onClick={handleCalloutVisibility}
        />
      </div>
      {isCalloutVisible && (
        <Callout
          className={styles.callout}
          role="alertdialog"
          gapSpace={0}
          target={`.${styles.buttonArea}`}
          onDismiss={handleCalloutVisibility}
          setInitialFocus
        >
          <>
            <div className={styles.header}>
              <Text className={styles.title}>
                {user.name}
              </Text>
            </div>

            <div className={styles.inner}>
              <div className={subtitleContainer}>
                  <Text className={styles.subtitle}>{user.emails[0]}</Text>
              </div>
              <div className={btnsContainer}>
                <PrimaryButton styles={btnS} onClick={logout}>Wyloguj</PrimaryButton>
                {/* <DefaultButton styles={btnS} onClick={openSelfEditPanel}>Profil</DefaultButton> */}
              </div>
            </div>
          </>
        </Callout>
      )}
    </>
  );
};

export default UserIcon;
