import * as React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { AdminLayoutRoute } from "./AdminLayoutRoute";
import { NavigationPath } from "./NavigationPath";
import Layout from "../../features/common/layout/Layout";
import { userInfo } from "../../connectors/auth/connectors";
import { useQuery } from "react-query";
import ReportCommandBar from "../../features/commandBar/components/report/ReportCommandBar";
import Reports from "../../features/reports/pages/Reports";
import ActivityIndicator from "../../features/common/layout/ActivityIndicator";

const Routing: React.FC = () => {

  const { data, status } = useQuery("userinfo", userInfo);
  const fetchedData = data ? data.data : undefined
  const isActivated = fetchedData ? fetchedData.isActivated : 'false';
  const hasAllRequiredData = fetchedData ? fetchedData.hasAllRequiredData : 'false';
  const role = fetchedData ? fetchedData.role : '';
  sessionStorage.setItem('isActivated', isActivated);
  sessionStorage.setItem('hasAllRequiredData', hasAllRequiredData);
  sessionStorage.setItem('role', role);

  if (status === "loading") {
    return <>
      <ActivityIndicator showText={true}/>
    </>
  }
  return (
    <Switch>
      <AdminLayoutRoute
        exact
        path={NavigationPath.HOME}
        commandBar={ReportCommandBar}
        layout={Layout}
        component={Reports}
      />
    </Switch>
  );
};

export default Routing;
