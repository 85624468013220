import * as React from "react";
import {
  CommandBar,
} from "office-ui-fabric-react";
import { comandBarS} from "../../styles/commandBar.styles"
import { useReportsCommandBar} from "../hooks/useReportsCommandBar"

const ReportCommandBar: React.FC = () => {
  
  
  const {items} = useReportsCommandBar()
  return <CommandBar items={items} styles={comandBarS} />;
}
 
export default ReportCommandBar;

  //to do podmiany
    // const items: ICommandBarItemProps[] = [
    //   {
    //     key: 'newItem',
    //     text: 'New',
    //     cacheKey: 'myCacheKey', // changing this key will invalidate this item's cache
    //     iconProps: { iconName: 'Add' },
    //     subMenuProps: {
    //       items: [
    //         {
    //           key: 'emailMessage',
    //           text: 'Email message',
    //           iconProps: { iconName: 'Mail' },
    //           ['data-automation-id']: 'newEmailButton', // optional
    //         },
    //         {
    //           key: 'calendarEvent',
    //           text: 'Calendar event',
    //           iconProps: { iconName: 'Calendar' },
    //         },
    //       ],
    //     },
    //   },
    //   {
    //     key: 'upload',
    //     text: 'Upload',
    //     iconProps: { iconName: 'Upload' },
    //     href: 'https://developer.microsoft.com/en-us/fluentui',
    //   },
    //   {
    //     key: 'share',
    //     text: 'Share',
    //     iconProps: { iconName: 'Share' },
    //     onClick: () => console.log('Share'),
    //   },
    //   {
    //     key: 'download',
    //     text: 'Download',
    //     iconProps: { iconName: 'Download' },
    //     onClick: () => console.log('Download'),
    //   },
    // ];
    //const {items} = useDriversCommandBar()
