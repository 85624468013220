import * as yup from "yup";

export const validationSchemaReports = yup.object().shape({
    leadingPersonPhoneNumber: yup.string().required("Należy wybrać osobę prowadzącą"),
    //lawFirmName: yup.string().required("To pole nie może być puste").max(100,"Ilość znaków nie może być większa niż 100"),
    proceedingType: yup.string().required("Należy wybrać jedną z dostępnych opcji"),
    bankStatus: yup.string().required("Należy wybrać jedną z dostępnych opcji"),
    // opposingParty: yup.string().required("To pole nie może być puste"),
    // court: yup.string().required("To pole nie może być puste"),
    // caseFileReference: yup.string().required("To pole nie może być puste"),
    subjectMatter: yup.string().required("To pole nie może być puste"),
    // caseCourse: yup.string().required("To pole nie może być puste"),
    // riskAssesment: yup.string().required("To pole nie może być puste"),
    // subjectMattersValue: yup.number().min(0, "Kwota nie może byc ujemna"),
    // conductRisk: yup.string().required("Należy wybrać jedną z dostępnych opcji"),
    // legalFees: yup.number().min(0, "Kwota nie może byc ujemna"),
    // representationCost: yup.number().min(0, "Kwota nie może byc ujemna"),
    //otherCosts: yup.number().min(0, "Kwota nie może byc ujemna"),
    //claimValue: yup.number().min(0, "Kwota nie może byc ujemna"),
    //losingProbability: yup.string().required("Należy wybrać jedną z dostępnych opcji"),
    //endsThisYear: yup.string().required("Należy wybrać jedną z dostępnych opcji")
});
