import { IReportsAction } from "./reportsAction.types"
import { ReportsActions } from "./reportsAction.enum";

const initState = {
    selectedReports: [],
    activeReport: {},
    reportPanelOpen: false,
    reportEditPanel: false,
    reloadReportsList: false,
    addReport: false,
    reloadReports: false,
    remove: false,
    reportDownloadLoading: false,
    removeConfirmation:false
  };
  
  export const reportsReducer = (state = initState, action: IReportsAction) => {
    const { type, payload } = action;

    switch (type) {
        case ReportsActions.SET_SELECTED_REPORTS:
            return { ...state, selectedReports: payload};
        case ReportsActions.TOGGLE_REPORTS_PANEL:
            return { ...state, reportPanelOpen: payload.open, reportEditPanel: payload.edit};
        case ReportsActions.RELOAD_REPORTS:
            return { ...state, reloadReportsList: payload.reload };
        case ReportsActions.SET_REPORT:
            return { ...state, activeReport: payload };
        case ReportsActions.ADD_REPORT:
            return { ...state, addReport: payload.add };
        case ReportsActions.REMOVE_DATA:
            return { ...state, remove: payload.remove };
        case ReportsActions.SET_ACTIVE_REPORT:
            return { ...state, activeReport: payload };
        case ReportsActions.TOGGLE_REPORT_DOWNLOAD_LOADING:
            return { ...state, reportDownloadLoading: payload };
        case ReportsActions.TOGGLE_REMOVE_CONFIRMATION:
            return { ...state, removeConfirmation: payload}        
        default:
            return state;
    }
};