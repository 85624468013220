import { clearAlerts, setListMessage, setErrorAlert } from '../../../config/redux/alerts/alertsActions';
import { useDispatch, RootStateOrAny, useSelector } from "react-redux";
import { FormikValues } from "formik";

import { toggleExcelPanel } from "../../../config/redux/excel/excelAction";
//import { addReport, editReport} from "../../../connectors/reports/connectors/index";
//import { IExcel } from "../../../connectors/excel/models/index"
import { IResetForm } from "../../common/models/resetForm.type";
import { useState } from "react";

import api from "../../../config/axios/configAxios";
import { toggleReportDownloadLoading } from '../../../config/redux/reports/reportActions';

export const useExcelForm = () => {
  const [loading, setIsLoading] = useState(false)
//   const { selectedReports, activeDriver } = useSelector(
//     (state: RootStateOrAny) => state.excel
//   );

const { excelPanelOpen } = useSelector(
    (state: RootStateOrAny) => state.excel
  );
  const dispatch = useDispatch();
  let initValues = {
    dateFrom: '2020-01-01',
    dateTo: '2021-03-09',
  };

  const downloadReport = async () => {
    //console.log("Excel ")
    //api.get("/api/Function/excel/1?DateFrom="+data.dateFrom+"&DateTo="+data.dateTo+"T23:59:59", {responseType: 'blob', }
    dispatch(toggleReportDownloadLoading(true))
    api.get("/api/Function/excel/1", {responseType: 'blob', }
    ).then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'Raport.xlsx');
      document.body.appendChild(link);
      link.click();
      dispatch(toggleReportDownloadLoading(false))
    });
  };
  
  const handleSubmit = async (
    values: FormikValues,
    { resetForm }: IResetForm
  ) => {

    setIsLoading(true)   
    const body = {
        dateFrom: values.dateFrom,
        dateTo: values.dateTo
    };
    //await downloadReport(body);
    dispatch(toggleExcelPanel({ open: false }));

    // try {
    //   dispatch(clearAlerts())
    //   selectedReports.length >= 1 ? await edit(body) : await add(body);
    //   setIsLoading(false)
    //   dispatch(setReport({}));
    //   dispatch(addNewReport({ add: true }));
    //   resetForm();
    //   dispatch(toggleReportsPanel({ open: false }));
    //   dispatch(setListMessage("Operacja przebiegła pomyślnie"))
    // } catch (err) {
    //   setIsLoading(false)
    //   dispatch(setErrorAlert("Użytkownik o podanym adresie email już istnieje"))
    // }
  };

  const cancelForm = () => {
    dispatch(toggleExcelPanel({ open: false }));
    dispatch(clearAlerts())
  };

  return {
    handleSubmit,
    initValues,
    cancelForm,
    //selectedReports,
    loading,
    downloadReport
  };
};
