import * as React from "react";
import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/Spinner";
import { Stack, IStackStyles } from "office-ui-fabric-react";

const spinnerS: IStackStyles = {
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: 20,
    height: "100vh",
    width: "100%",
  },
};

interface ActivityIndicatorProps{
  showText?: boolean 
}

const ActivityIndicator: React.FC<ActivityIndicatorProps> = ({showText}) => {
  return (
    <Stack styles={spinnerS}>
      <Spinner size={SpinnerSize.large} />
      {showText == true ? <p style={{paddingTop: 20}}>Uruchamianie aplikacji</p> : <></>}
    </Stack>
  );
};

export default ActivityIndicator;
