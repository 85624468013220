import * as React from "react";

import ListSuccesMessage from "../../common/layout/ListSuccesMessage";
import { mergeStyles } from "office-ui-fabric-react";
import ReportListA from "../components/ReportListA";
import ReportPanel from '../components/ReportPanel';
import ExcelPanel from '../components/ExcelPanel';
import { getUsersApi, getUserinfoApi } from "../../../connectors/reports/connectors";
import ReportDownloadLoader from "../components/ReportDownloadLoader";
import { useQuery } from "react-query";
import ReportDeleteConfirmation from "../components/ReportDeleteConfirmation";
import ReportShow from "../components/ReportShow";

const padding = mergeStyles({
    // paddingTop: 15
})

const wrapper = mergeStyles({
    // width: "95%",
    margin: "0px auto",
});

const UserinfoGet = (users: Array<any>) => {
    const { data } = useQuery("userinfo", getUserinfoApi);
    const userName = users.find(x => x.id == data?.data.objectId.toUpperCase());
    var userinfo = {
        id: data?.data.objectId.toUpperCase(),
        email: data?.data.email,
        key: userName ? userName.key : ""
    };
    return userinfo;
}

const Reports: React.FC = () => {
    const { data } = useQuery("users", getUsersApi)

    var users: Array<any> = [];
    data?.data.map((item: any) => {
        const userToAdd = {
            key: item.username,
            text: item.username,
            id: item.objectId
        }
        users.push(userToAdd);
    });

    const userinfo = UserinfoGet(users);
    //const userinfo = undefined
    return (
        <div className={wrapper}>
            <div className={padding}>
                <ListSuccesMessage />
            </div>
            <ReportListA />
            <ReportPanel
                users={users ? users : []}
                userinfo={userinfo ? userinfo : undefined}
            />
            <ExcelPanel />
            <ReportDownloadLoader />
            <ReportDeleteConfirmation />
        </div>
    );
};

export default Reports;