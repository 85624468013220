import * as React from "react";
import { Route, Redirect, useHistory } from "react-router-dom";
import { mergeStyles } from "office-ui-fabric-react";
import { NavigationPath } from "./NavigationPath";
import { authorize, Roles } from "./actions/authService";
// import SelfEditPanel from "../../features/Admins/components/panel/SelfEditPanel";

const main = mergeStyles({
  marginTop: "96px",
  // marginLeft: "230px",
  // width: "calc(100% - 230px)",
});

export const AdminLayoutRoute = ({
  component: Component,
  layout: Layout,
  commandBar: CommandBar,
  sideNav: SideNav,
  ...rest
}: any) => {
  const { isActivated, hasAllRequiredData } = authorize();
  
  return (
    <Route
      {...rest}
      render={(props) => {
          return (
            <>
              <Layout />
              {CommandBar && <CommandBar />}
              <div className={main}>
                {SideNav && <SideNav />}
                <Component {...props} />
                {/* <SelfEditPanel /> */}
              </div>
            </>
          );
        // }
      }}
    />
  );
};
