import {AllertsActions} from "./alertsActions.enum"

export const setErrorAlert = (message: string) => {
    return {
        type: AllertsActions.SET_ERROR_ALERT,
        payload: message
    }
}


export const setSuccessAlert = (message: string) => {
    return {
        type: AllertsActions.SET_SUCCES_ALERT,
        payload: message
    }
}

export const setListMessage = (message: string) => {
    return {
        type: AllertsActions.SET_LIST_MESSAGE,
        payload: message
    }
}

export const setDialogError = (message: string) => {
    return{
        type: AllertsActions.SET_DIALOG_ERROR,
        payload: message
    }
}

export const clearAlerts = () => {
    return{
        type: AllertsActions.CLEAR_ALERTS
    }
}

export const clearListMessages = () => {
    return{
        type: AllertsActions.CLEAR_LIST_MESSAGES
    }
}

export const setListErr = (message: string) => {
    return {
        type: AllertsActions.SET_LIST_ERR,
        payload: message
    }
}

