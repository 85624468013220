import { IFilterAction } from "./filterActions.types";
import {FiltersActions} from "./filteractions.enum"

const initState = {
  searchPhrase: "",
  filter: false,
};

export const filtersReducer = (state = initState, action: IFilterAction) => {
  const { type, payload } = action;
  switch (type) {
    case FiltersActions.SET_SEARCH_PHRASE:
      return { ...state, searchPhrase: payload.searchPhrase };
    case FiltersActions.SET_SORTING:
      return { ...state, column: payload.column };
    case FiltersActions.TOGGLE_FILTRATION:
      return { ...state, filter: payload.filter };
    case FiltersActions.ClEAR:
      return { ...initState };
    default:
      return state;
  }
};
