import { IReport, IReportPanel, IReload, IAdd, IRemove, IActiveReport} from './reportsAction.types'
import { ReportsActions} from './reportsAction.enum'
import Reports from '../../../features/reports/components/ReportForm'

export const setReport = (data: IReport) => {
    return{
        type: ReportsActions.SET_REPORT,
        payload: data
    }
}

export const setSelectedReports = (data: Array<IReport>) => {
    return {
        type: ReportsActions.SET_SELECTED_REPORTS,
        payload: data
    }
}

export const setActiveReport = (data: IActiveReport) => {
    return {
      type: ReportsActions.SET_ACTIVE_REPORT,
      payload: data
    }
  }

export const toggleReportsPanel = (data: IReportPanel) => {
    return {
        type: ReportsActions.TOGGLE_REPORTS_PANEL,
        payload: data
    }
}

export const reloadReports = (data: IReload) => {
    return {
        type: ReportsActions.RELOAD_REPORTS,
        payload: data
    }
}

export const clearReports = () => {
    return {
        type: ReportsActions.CLEAR
    }
}

export const addNewReport = (data: IAdd) => {
    return {
        type: ReportsActions.ADD_REPORT,
        payload: data
    }
}

export const removeData = (data: IRemove) => {
    return {
      type: ReportsActions.REMOVE_DATA,
      payload: data,
    };
  };

export const toggleRemoveConfirmation = (loading: boolean) => {
    return {
        type: ReportsActions.TOGGLE_REMOVE_CONFIRMATION,
        payload: loading
    }
}

export const toggleReportDownloadLoading = (loading: boolean) => {
    return{
      type: ReportsActions.TOGGLE_REPORT_DOWNLOAD_LOADING,
      payload: loading
    }
}
  