import moment from "moment";

export const parseReports = (items: any) => {
    //var itemsArray = JSON.parse(items);

    var reports: any = [];

    items.map((item: any) => {
        // if(item.CanEdit === true){
        // const date = Date.parse(item.SueDate);
        // var date2 = new Date(date); //todo
        // var d = date2.getDate();
        // var m = date2.getMonth() + 1;
        // var y = date2.getFullYear();
        // var dateStringToShow = (d <= 9 ? '0' + d : d) + '.' + (m <= 9 ? '0' + m : m) + '.' + y;
        // var dateString =  y+ '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
        //dateString = dateString.toISOString().substring(0, 10);
        //{moment(post.date).format()}
        let recentChangedColumnsListTemp: any[] = item.recentChangedColumnsList;
        let recentChangedColumnsList = recentChangedColumnsListTemp.map(x => x.toLowerCase())
        const objToAdd = {
            key: item.id,
            id: item.id,
            canEdit: item.canEdit,
            leadingPersonPhoneNumber: item.leadingPerson,
            leadingPersonObjectId: item.LeadingPersonObjectId,
            lawFirmName: item.lawFirmName,
            proceedingType: item.proceedingType,
            bankStatus: item.bankStatus,
            opposingParty: item.opposingParty,
            court: item.court,
            caseFileSignature: item.caseFileSignature,
            //blue
            sueDate: moment(item.sueDate).format('YYYY-MM-DD'),
            sueDateToShow: moment(item.sueDate).format('MM.DD.YYYY'),
            subjectMatter: item.subjectMatter,
            caseCourse: item.caseCourse,
            hasFinished: item.hasFinished === true ? "Tak" : "Nie",
            riskAssesment: item.riskAssesment,
            subjectMattersValue: item.subjectMattersValue,
            conductRisk: item.conductRisk === true ? "Tak" : "Nie",
            //green
            legalFees: item.legalFees,
            representationCost: item.representationCost,
            otherCosts: item.otherCosts,
            claimValue: item.claimValue,
            losingProbability: item.losingProbability === "Bank raczej wygra" ? "Bank raczej wygra" : "Bank raczej przegra",
            endsThisYear: item.endsThisYear === "Raczej tak" ? "Raczej tak" : "Raczej nie",
            recentChangedColumnsList: recentChangedColumnsList,

        }
        reports.push(objToAdd);
        // }
    });
    return reports;
};