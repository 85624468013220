import React from "react";
import { PrimaryButton, Stack, DefaultButton, mergeStyles } from "office-ui-fabric-react";
import { container, buttonS } from "../../styles/formBtns.styles";

export interface NewFormBtnsProps {
  cancelForm: any;
  submitBtnText?: string;
  cancelBtnText?: string;
  disabled?: boolean,
  positionAbsolute?: boolean
}

const btns = mergeStyles({
  position: "absolute",
  bottom: 30,
  left: 40,
});

const NewFormBtns: React.FC<NewFormBtnsProps> = ({
  cancelForm,
  submitBtnText,
  cancelBtnText,
  disabled,
  positionAbsolute = false
}) => {
  return (
   <div className={positionAbsolute ? btns : ""}>
      <PrimaryButton
        text={submitBtnText ? submitBtnText : "Zatwierdź"}
        type="submit"
        styles={buttonS}
        disabled={disabled}
        // form='form'
      />
      <DefaultButton
        text={cancelBtnText ? cancelBtnText : "Anuluj"}
        type="reset"
        onClick={cancelForm}
        disabled={disabled}
      />
   </div>
  );
};

export default NewFormBtns;
