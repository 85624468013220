import { Spinner, SpinnerSize } from "office-ui-fabric-react/lib/components/Spinner";
import { Text, ITextStyles } from "office-ui-fabric-react/lib/Text";
import Dialog from "office-ui-fabric-react/lib/Dialog";
import * as React from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { Stack } from "office-ui-fabric-react";
import { DefaultPalette, IDialogContentStyles, IDialogStyleProps, IStackItemStyles, IStackTokens } from "@fluentui/react";
import { IStyleFunctionOrObject } from "office-ui-fabric-react";

const textS: ITextStyles = {
  root: {
    fontSize: 14,
    fontWeight: 600,
  }
}

const stackItemStyles: IStackItemStyles = {
  root: {
    padding: 5,
  },
};

const verticalGapStackTokens: IStackTokens = {
  childrenGap: 20,
  padding: 10,
};

const dialogS: IStyleFunctionOrObject<IDialogStyleProps, IDialogContentStyles> = {
  header: {
      height: 0
  },

  inner:{
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   padding: 20,
  }
}

const ReportDownloadLoader: React.FC = () => {
  const { reportDownloadLoading } = useSelector(
    (state: RootStateOrAny) => state.reports
  );

  return (
    <Dialog hidden={!reportDownloadLoading} dialogContentProps={{ showCloseButton: false , styles: dialogS}} >
      <Stack tokens={verticalGapStackTokens}>
        <Stack.Item align="center" styles={stackItemStyles}>
          <Text styles={textS}>Trwa generowanie raportu.</Text>
        </Stack.Item>
        <Stack.Item align="center" styles={stackItemStyles}>
          <Spinner size={SpinnerSize.large} />
        </Stack.Item>
      </Stack>
    </Dialog>
  );
};

export default ReportDownloadLoader;
