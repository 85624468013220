import { combineReducers } from "redux";
import { filtersReducer } from "./filters/filtersReducer";
import { alertsReducer } from "./alerts/alertsReducer";
import { reportsReducer } from "./reports/reportReducer"
import { excelReducer } from "./excel/excelReducer";

const rootReducer = combineReducers({
  filtration: filtersReducer,
  alerts: alertsReducer,
  reports: reportsReducer,
  excel: excelReducer
});

export default rootReducer;
