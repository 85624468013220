import { IQuantity, Quantity } from "../../common/models/quantity.model";

interface IActions {
  openPanelToAddReport: () => void;
  openPanelToEditReport: () => void;
  openPanelToShowReport: () => void;
  removeReport: () => void;
  openExcelPanel: () => void;
}

export const generateItems = (quantity: IQuantity, actions: IActions, canEdit: boolean) => {
  switch (quantity) {
    case Quantity.NONE:
      return [
        {
          key: "generateReport",
          text: "Pobierz raport",
          iconProps: { iconName: "ReportDocument" },
          name: "generateReport",
          onClick: actions.openExcelPanel,
        },
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          name: "add",
          onClick: actions.openPanelToAddReport,
        },
      ];
    case Quantity.ONE:
      return [
        {
          key: "generateReport",
          text: "Pobierz raport",
          iconProps: { iconName: "ReportDocument" },
          name: "generateReport",
          onClick: actions.openExcelPanel,
        },
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          name: "add",
          onClick: actions.openPanelToAddReport,
        },
        {
          key: "show",
          text: "Pokaż",
          iconProps: { iconName:"textDocument"},
          name: "show",
          onClick: actions.openPanelToShowReport,
        },
        canEdit ? {
          key: "edit",
          text: "Edytuj",
          iconProps: { iconName:"edit"},
          name: "edit",
          onClick: actions.openPanelToEditReport,
        } : {key: "edit"} 
        ,
        canEdit ? 
        {
          key: "delete",
          text: "Usuń",
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.removeReport,
        } : {key: "delete"} 
      ];
    case Quantity.MANY:
      return [
        {
          key: "generateReport",
          text: "Pobierz raport",
          iconProps: { iconName: "ReportDocument" },
          name: "generateReport",
          onClick: actions.openExcelPanel,
        },
        {
          key: "add",
          text: "Dodaj",
          iconProps: { iconName: "add" },
          name: "add",
          onClick: actions.openPanelToAddReport,
        },
        canEdit ? {
          key: "delete",
          text: "Usuń",
          iconProps: { iconName: "delete" },
          name: "delete",
          onClick: actions.removeReport,
        } : { key: "delete" },
      ];
    default:
      return [];
  }
};